import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { login, logout } from '@/api/api';

import { getToken, setToken, removeToken, removeFreight, getUserData, setUserDatan, getVerify, setVerify } from '@/utils/cookies';
import store from '@/store';
import { getUUID } from '@/utils/base';

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule {
    public GUID = getVerify();
    public token = getToken() || '';
    public userData = getUserData() || {};
    public name = '';
    public avatar = '';
    public accountPassword = JSON.parse(localStorage.getItem('AccountPassword')) || { UserName: '', PassWord: '' };

    @Mutation
    private SET_GUID(data: string) {
        this.GUID = data;
        setVerify(data);
    }

    @Mutation
    private SET_TOKEN(token: string) {
        this.token = token;
    }

    @Mutation
    private SET_USER_DATA(obj: object) {
        setUserDatan(obj);
        this.userData = obj;
    }

    @Mutation
    private SET_NAME(name: string) {
        this.name = name;
    }

    // 设置头像
    @Mutation
    private SET_AVATAR(avatar: string) {
        this.avatar = avatar;
    }

    // 保存账号密码
    @Mutation
    private SET_ACCOUNT_PASSWORD(userInfo: { UserName: string, PassWord: string }) {
        this.accountPassword = userInfo;
        localStorage.setItem('AccountPassword', JSON.stringify(userInfo));
    }

    @Action
    public async Login(userInfo: { UserName: string, PassWord: string }) {
        const uuid = this.GUID;
        const { Data } = await login(userInfo, {
            headers: {
                VerifyCookie: uuid
            }
        });
        setToken(Data.Token);
        this.SET_TOKEN(Data.Token);
        this.SET_USER_DATA(Data);
    }

    @Action({ rawError: true })
    public async roleRouter() {
        if (this.token === '') {
            throw Error('令牌未定义！');
        }
        // const { Data } = await getRouter();
        // if (!Data || Data.length <= 0) {
        //     throw new Error('该账号没有权限，请联系管理员！');
        // }
        // this.SET_AVATAR(require('@/assets/user_avatar.gif'));
    }

    @Action
    public ResetToken() {
        removeToken();
        this.SET_TOKEN('');
    }

    @Action
    public async LogOut() {
        if (this.token === '') {
            throw Error('注销：令牌未定义！');
        }
        await logout();
        removeToken();
        removeFreight();
        this.SET_TOKEN('');
    }

    @Action
    public setUUid() {
        this.SET_GUID(getUUID() + new Date().getTime());
    }

    @Action
    public setAccountPassword(userInfo: { UserName: string, PassWord: string }) {
        this.SET_ACCOUNT_PASSWORD(userInfo);
    }

}

export const UserModule = getModule(User);
