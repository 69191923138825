































import { Component, Vue } from 'vue-property-decorator';
import { appConfigModule } from '@/store/modules/appConfig';

@Component({
    name: 'Menu',
    components: {
    }
})
export default class Menu extends Vue {

    public routerData: any = [
        { label: this.$t('menu.menu1'), path: 'Home', icon: 'icon-shouye', children: [] },
        { label: this.$t('menu.menu2'), path: 'Standard', icon: 'icon-shoufeibiaozhun', children: [] },
        { label: this.$t('menu.menu3'), path: 'ActivityIndex', icon: 'icon-youhuihuodong', children: [] },
        {
            label: this.$t('menu.menu4'),
            path: '',
            icon: 'icon-a-01yunshuzhongwuliu-xianxing2',
            children: [
                { label: this.$t('menu.menu4-1'), path: 'Introduce' },
                { label: this.$t('menu.menu4-2'), path: 'Teaching' },
                { label: this.$t('menu.menu4-3'), path: 'Route' },
                { label: this.$t('menu.menu4-4'), path: 'Terms' }
            ]
        },
        {
            label: this.$t('menu.menu5'),
            path: '',
            icon: 'icon-guanyuwomen',
            children: [
                { label: this.$t('menu.menu5-1'), path: 'EnterpriseOverview' },
                { label: this.$t('menu.menu5-2'), path: 'BusinessConsulting' },
                { label: this.$t('menu.menu5-3'), path: 'EnterpriseStyle' },
                { label: this.$t('menu.menu5-4'), path: 'BusinessCooperation' }
            ]
        },
        { label: this.$t('menu.menu6'), path: 'CommodityIndex', icon: 'icon-tejiashangpin', children: [] }

    ];

    private created() {
        // 如果等于泽宝
        // eslint-disable-next-line eqeqeq
        if (this.shopId == '7') {
            this.routerData[3].children[2] = { label: this.$t('menu.menu4-5'), path: 'SelfPromotion' };
        }
    }

    // 选择菜单列表
    public select(item) {
        // if (item.path === 'CommodityIndex') {
        //     // 文档 https://open.taobao.com/api.htm?spm=a21wu.24207461-hk.6280928830.15.45162905rf4XsI&docId=61178&docType=2&scopeId=23990
        //     window.open('https://ai.world.taobao.com/search?site=HK&pid=mm_26632943_457000242_108856900407&relationId=2856283783&sort=total_sales');
        //     return;
        // }
        // 如果存在下拉列表 不给点击
        if (item.children && item.children.length !== 0) {
            return;
        }
        // 如果已经在当前路由，不给跳转
        if (item.path === this.activeMenu) {
            return;
        }
        this.$router.push({
            name: item.path
        });
    }

    // 鼠标经过
    public mouseenter(index) {
        if (this.routerData[index].children && this.routerData[index].children.length > 0) {
            const emelent = this.$refs[`poput${index}`][0];
            emelent.className = 'menu-poput-box active';
        }
    }

    // 鼠标移出
    public mouseleave(index) {
        if (this.routerData[index].children && this.routerData[index].children.length > 0) {
            const emelent = this.$refs[`poput${index}`][0];
            emelent.className = 'menu-poput-box';
        }
    }

    get activeMenu() {
        const route = this.$route;
        return route?.name || '';
    }

    public get shopId() {
        return appConfigModule.appConfig.shopId;
    }
}
